import React from 'react';
import PageTitle from './page-title';
export default class Account extends React.Component {
  render() {
    return (
      <div>
        <PageTitle page_title="Help" />
      </div>
    )
  }
}